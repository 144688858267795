'use client'
import { createCn } from 'shared-utils'
import { GrandSection, ParsedHtml } from 'ui'
import type { FC } from 'react'
import { Box } from '@chakra-ui/react'
import type { ContentBoxWidgetType, StorefrontWidget } from 'ecosystem'
import type { WidgetImplementation } from '../utils'

const cn = createCn('content-box')

const WIDGET_CN = {
  SECTION: cn('article'),
  SECTION_CONTAINER: cn('section-container'),
  CONTENT: cn('content')
}

type ContextBoxProps = WidgetImplementation<StorefrontWidget<ContentBoxWidgetType>>

const ContentBox: FC<ContextBoxProps> = ({
  widgetId,
  widget: {
    widget: { content }
  }
}) => {
  return (
    <GrandSection
      className={WIDGET_CN.SECTION}
      contentContainerProps={{
        className: WIDGET_CN.SECTION_CONTAINER
      }}
      sectionId={widgetId}>
      <Box
        as="article"
        className={WIDGET_CN.CONTENT}
        sx={{
          '*+*': {
            mt: 5
          },

          'h1, h2, h3, h4': {
            fontFamily: 'heading',
            textAlign: 'left'
          },

          'h1, h2': {
            fontWeight: 'medium',
            lineHeight: 1.4,
            mb: 6
          },

          h1: {
            fontSize: {
              base: '3xl',
              md: '4xl'
            }
          },

          h2: {
            fontSize: '2xl'
          },

          h3: {
            fontWeight: 'normal',
            fontSize: 'xl',
            lineHeight: 1.4
          },

          h4: {
            fontWeight: 'normal',
            fontSize: 'lg',
            lineHeight: 1.4
          },

          h5: {
            fontWeight: 'normal',
            fontSize: {
              base: 'md',
              md: 'lg'
            },
            lineHeight: 1.9
          },

          p: {
            fontWeight: 400,
            fontSize: 'md',
            lineHeight: 1.9
          },

          ul: {
            listStyleType: 'disc',
            pl: '1.4em'
          },

          'ul, ul > li': {
            listStylePosition: 'outside'
          },

          '*+h1, *+h2': {
            mt: 12
          },
          '*+h3, *+h4': {
            mt: 10
          },
          a: {
            color: 'primary'
          }
        }}
        w="100%">
        <ParsedHtml html={content || ''} />
      </Box>
    </GrandSection>
  )
}

export default ContentBox
