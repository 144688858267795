export const WIDGET_GROUP_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  SECTION_TITLE: 'section_title',
  SECTION_TITLE_CONTAINER: 'section_title_container',
  SECTION_TITLE_DECORATION: 'section_title_decoration',
  SECTION_DESC: 'section_description',
  SECTION_LINK_WRAPPER: 'section_link_wrapper',
  WRAPPER: 'wrapper',
  ITEM: 'item',
  ITEM_WRAPPER: 'item_wrapper',
  ITEM_TEXT_WRAPPER: 'item_text_wrapper',
  ITEM_TAG: 'tag',
  ITEM_TITLE: 'heading',
  ITEM_TITLE_CONTAINER: 'item_title_container',
  ITEM_TITLE_DECORATION: 'item_title_decoration',
  ITEM_OVERLAY: 'item_overlay',
  ITEM_DECORATION: 'item_decoration',
  ITEM_SUBTITLE: 'item_subtitle',
  ITEM_LINK_LABEL: 'item_link_label',
  ITEM_LINK_LABEL_CONTAINER: 'item_link_label_container'
}
