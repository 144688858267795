import { GrandNextLink, ResponsiveTextBox } from 'ui'
import { Box, Flex } from '@chakra-ui/react'
import { IoIosArrowForward } from 'react-icons/io'
import type { BubbleLinkWidgetItemType } from 'ecosystem'
import { WIDGET_GROUP_CN } from '../../constants'
import { bubbleLinkCn as cn } from './constants'

interface BubbleLinkWidgetItemProps {
  item: BubbleLinkWidgetItemType
}

export const BubbleLinkWidgetItem = ({ item }: BubbleLinkWidgetItemProps) => {
  return (
    <Flex
      alignItems="center"
      bg="background.translucent"
      borderRadius="full"
      borderBottomLeftRadius="0"
      className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
      gap={2}
      justifyContent="space-between"
      key={item.id}
      overflow="hidden"
      p={2}
      px={4}
      {...(item.path
        ? {
            as: GrandNextLink,
            href: item.path,
            'aria-label': item.title,
            isExternal: item.isExternal
          }
        : {})}>
      <ResponsiveTextBox
        className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
        fontSize="sm"
        fontWeight="normal"
        pl={2}
        textAlign="start"
        w="auto">
        {item.title}
      </ResponsiveTextBox>

      <Box>
        <IoIosArrowForward />
      </Box>
    </Flex>
  )
}
