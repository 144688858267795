'use client'
import type { FC } from 'react'
import { EmptyMessage } from 'ui'
import { type IEmptyMessage } from 'ui/common/EmptyMessage'

type PlaceholderWidgetProps = Partial<IEmptyMessage> & {
  widgetId: string
}

const PlaceholderWidget: FC<PlaceholderWidgetProps> = ({ widgetId, ...props }) => {
  return (
    <EmptyMessage
      my={12}
      text={`This widget needs custom store implementation: ${widgetId}.`}
      {...props}
    />
  )
}

export default PlaceholderWidget
