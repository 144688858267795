import type { StackProps } from '@chakra-ui/react'
import {
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack
} from '@chakra-ui/react'
import type { ArticleSizedBoxesWidgetItemType, Ratio } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { articleSizedBoxesCn as cn } from './constants'

type ArticleSizedBoxesWidgetLargeItemProps = StackProps &
  ArticleSizedBoxesWidgetItemType & {
    aspectRatio?: Ratio
    mobileAspectRatio?: Ratio
  }

const ArticleSizedBoxesWidgetLargeItem = ({
  title,
  subtitle,
  showSubOnMobile,
  sub = '',
  imgUrl,
  path,
  isExternal,
  label: linkLabel,
  aspectRatio = '16:9',
  mobileAspectRatio = '16:9',
  ...props
}: ArticleSizedBoxesWidgetLargeItemProps) => {
  const isLink = Boolean(path)
  const content = useBreakpointValue({
    base: showSubOnMobile ? sub : '',
    md: sub
  })

  return (
    <VStack
      alignItems="stretch"
      as={isLink ? GrandLink : 'div'}
      className={cn('item')}
      fontSize={{
        base: 'xs',
        md: 'md'
      }}
      spacing={{
        base: 2,
        md: 4
      }}
      w="full"
      {...(isLink
        ? {
            href: path || '',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}
      {...props}>
      {subtitle ? (
        <Heading
          as="div"
          className={cn(WIDGET_GROUP_CN.ITEM_SUBTITLE)}
          color="text.darkGrey"
          fontSize={{
            base: 'xs',
            md: 'xl'
          }}
          fontWeight="normal">
          {subtitle}
        </Heading>
      ) : null}

      <SimpleGrid
        className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
        columns={{
          base: 1,
          md: 2
        }}
        spacing={{
          base: 4,
          md: 10
        }}
        w="full">
        {imgUrl ? (
          <GridItem className={cn('item_img_wrapper')} colSpan={1}>
            <GrandAspectRatio
              borderRadius="md"
              breakpoints={{ base: mobileAspectRatio, sm: aspectRatio }}
              overflow="hidden">
              <GrandImage
                alt={title || ''}
                fill
                objectFit="cover"
                sizes="(min-width: 52em) 50vw, 100vw"
                src={imgUrl}
              />

              <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
            </GrandAspectRatio>
          </GridItem>
        ) : null}

        <GridItem
          alignItems="stretch"
          as={VStack}
          className={cn('item_content_wrapper')}
          flex={1}
          justifyContent="space-between"
          spacing={4}
          w="full">
          <VStack alignItems="stretch" spacing={4} w="full">
            {title ? (
              <GrandHeading
                className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
                containerProps={{
                  my: 0,
                  mr: 0
                }}
                fontSize={{
                  base: 'sm',
                  md: '2xl'
                }}
                fontWeight="medium"
                headingTag="h4"
                title={title}
                withDecoration={false}
              />
            ) : null}

            {content ? (
              <Flex w="full">
                <HTMLArticle className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} content={content} />
              </Flex>
            ) : null}
          </VStack>

          {linkLabel ? (
            <Flex>
              <Text
                _hover={{
                  textDecoration: 'none'
                }}
                fontWeight="semibold"
                textDecoration="underline">
                {linkLabel}
              </Text>
            </Flex>
          ) : null}
        </GridItem>
      </SimpleGrid>
    </VStack>
  )
}

export default ArticleSizedBoxesWidgetLargeItem
