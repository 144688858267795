'use client'
import type { FC } from 'react'
import { Fragment } from 'react'
import { VStack } from '@chakra-ui/react'
import type { IGrandSection } from 'ui'
import { GrandSection, GrandText } from 'ui'
import type { StorefrontWidget, SummaryBoxesWidgetType } from 'ecosystem'
import type { WidgetImplementation } from '../../utils'
import { WIDGET_GROUP_CN } from '../../constants'
import SummaryBoxesWidgetItemComponent from './SummaryBoxesWidgetItem'
import { summaryBoxesCn as cn } from './constants'

export type SummaryBoxesWidgetHandlerProps = WidgetImplementation<
  StorefrontWidget<SummaryBoxesWidgetType>
> &
  Omit<IGrandSection, 'sectionId'>

export const SummaryBoxesWidgetHandler: FC<SummaryBoxesWidgetHandlerProps> = ({
  widget: {
    widget: { title, description, items }
  },
  widgetId,
  ...props
}) => {
  return (
    <GrandSection
      bg="background.surface"
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER),
        px: 0
      }}
      sectionId={widgetId}
      title={title}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {description ? (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>{description}</GrandText>
      ) : null}

      <VStack className={cn(WIDGET_GROUP_CN.WRAPPER)} spacing={10}>
        {items.map((item) => (
          <Fragment key={item.id}>
            {' '}
            <SummaryBoxesWidgetItemComponent
              item={item}
              type={item.isReversed ? 'right' : 'left'}
            />
          </Fragment>
        ))}
      </VStack>
    </GrandSection>
  )
}
