'use client'
import type { FC } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import { createCn } from 'shared-utils'
import type { ImageWidgetType, StorefrontWidget } from 'ecosystem'
import { GrandAspectRatio, GrandImage, GrandOverlay, GrandSection, HTMLArticle } from 'ui'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import type { WidgetImplementation } from '../utils'

const cn = createCn('image-widget')

const WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  TITLE: 'title',
  TITLE_DECORATION: 'title_decoration',
  TITLE_CONTAINER: 'title_container',
  CONTENT: 'content',
  OVERLAY: 'overlay'
}

type ImageWidgetProps = WidgetImplementation<StorefrontWidget<ImageWidgetType>>

const ImageWidget: FC<ImageWidgetProps> = ({
  widgetId,
  widget: {
    widget: { title, sub: content, imgUrl, mobileImgUrl, aspectRatio, mobileAspectRatio }
  }
}) => {
  const { title: siteTitle = '', defaultImgSrc } = useUIGlobalsContext()
  const imageSrc = useBreakpointValue(
    {
      base: mobileImgUrl || imgUrl,
      md: imgUrl
    },
    {
      fallback: ''
    }
  )

  return (
    <GrandSection
      className={cn(WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_CN.SECTION_CONTAINER),
        spacing: 0,
        gap: 10
      }}
      sectionId={widgetId}
      titleProps={{
        title,
        className: cn(WIDGET_CN.TITLE),
        decorationProps: {
          className: cn(WIDGET_CN.TITLE_DECORATION)
        },
        containerProps: {
          className: cn(WIDGET_CN.TITLE_CONTAINER)
        }
      }}>
      {content ? <HTMLArticle className={cn(WIDGET_CN.CONTENT)} content={content} /> : null}

      <GrandAspectRatio breakpoints={{ base: mobileAspectRatio || aspectRatio, md: aspectRatio }}>
        <GrandImage
          alt={title || siteTitle}
          fill
          objectFit={imageSrc ? 'contain' : 'cover'}
          src={imageSrc || defaultImgSrc}
        />

        <GrandOverlay bg="transparent" className={cn(WIDGET_CN.OVERLAY)} />
      </GrandAspectRatio>
    </GrandSection>
  )
}

export default ImageWidget
