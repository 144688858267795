import { createCn } from 'shared-utils'

export const twoColumnsCn = createCn('two-columns-widget')

export const TWO_COLUMNS_WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  TITLE: 'title',
  TITLE_DECORATION: 'title_decoration',
  LINK_LABEL: 'link_label',
  LEFT_COLUMN: 'left_column',
  LEFT_COLUMN_CONTENT: 'left_column_content',
  RIGHT_COLUMN: 'right_column',
  RIGHT_COLUMN_CONTENT: 'right_column_content',
  ITEM_WRAPPER: 'item_wrapper',
  ITEM_TITLE: 'item_title',
  ITEM_CONTENT: 'item_content'
}
