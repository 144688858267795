'use client'
import type { FC } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import type { StorefrontWidget, ColoredItemsWidgetType } from 'ecosystem'
import { GrandSection, GrandText, ScrollDragContainer } from 'ui'
import type { WidgetImplementation } from '../../utils'
import ColoredItemsWidgetItemBase from './ColoredItemsWidgetItemBase'
import { COLORED_ITEMS_WIDGET_CN, coloredItemsCn as cn } from './constants'

type IColoredItemsWidgetHandlerProps = WidgetImplementation<
  StorefrontWidget<ColoredItemsWidgetType>
> & {
  priority?: boolean
}

const ColoredItemsWidgetHandler: FC<IColoredItemsWidgetHandlerProps> = ({
  widget,
  widgetId,
  priority,
  ...props
}) => {
  const aspectRatio = widget.widget.itemsAspectRatio

  const renderItems = () => {
    switch (widget.widget.variant) {
      case 'sm':
        return (
          <Flex justifyContent="center">
            <ScrollDragContainer cursor="default">
              <Grid
                className={cn(COLORED_ITEMS_WIDGET_CN.WRAPPER)}
                gap={5}
                templateColumns={{
                  base: `repeat(${widget.widget.items.length}, minmax(120px, 200px))`,
                  md: `repeat(${widget.widget.items.length}, 200px)`
                }}>
                {widget.widget.items.map((item) => (
                  <GridItem as={Flex} flex="1" key={item.id}>
                    <ColoredItemsWidgetItemBase
                      aspectRatio={{ base: aspectRatio || '1:1' }}
                      imageProps={{
                        priority
                      }}
                      item={item}
                    />
                  </GridItem>
                ))}
              </Grid>
            </ScrollDragContainer>
          </Flex>
        )

      default:
        return (
          <Grid
            className={cn(COLORED_ITEMS_WIDGET_CN.WRAPPER)}
            gap={5}
            templateColumns={{
              base: `repeat(1, 1fr)`,
              md: `repeat(${widget.widget.items.length}, 1fr)`
            }}
            w="full">
            {widget.widget.items.map((item) => (
              <GridItem as={Flex} flex="1" key={item.id}>
                <ColoredItemsWidgetItemBase
                  aspectRatio={{ base: '16:9', md: aspectRatio || '1:1' }}
                  imageProps={{
                    priority
                  }}
                  item={item}
                  key={item.id}
                  titleProps={{
                    fontSize: {
                      base: 'sm',
                      md: 'xl'
                    },
                    p: 4
                  }}
                />
              </GridItem>
            ))}
          </Grid>
        )
    }
  }

  return (
    <GrandSection
      className={cn(COLORED_ITEMS_WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(COLORED_ITEMS_WIDGET_CN.SECTION_CONTAINER)
      }}
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(COLORED_ITEMS_WIDGET_CN.SECTION_TITLE),
        containerProps: {
          className: cn(COLORED_ITEMS_WIDGET_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(COLORED_ITEMS_WIDGET_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...props}>
      {widget.widget.description ? (
        <GrandText className={cn(COLORED_ITEMS_WIDGET_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      ) : null}

      {renderItems()}
    </GrandSection>
  )
}

export default ColoredItemsWidgetHandler
