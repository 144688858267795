'use client'
import type { FC } from 'react'
import { GrandAccordion, GrandSection, HTMLArticle } from 'ui'
import type { StorefrontWidget, WidgetGroup, AccordionWidgetItemType } from 'ecosystem'
import { useMemo } from 'react'
import type { WidgetImplementation } from '../utils'

type WidgetAccordionItemsProps = WidgetImplementation<
  StorefrontWidget<WidgetGroup<AccordionWidgetItemType>>
>

const WidgetAccordionItem: FC<WidgetAccordionItemsProps> = ({
  widgetId,
  widget: {
    widget: { items }
  }
}) => {
  const mapped = useMemo(() => {
    return (
      items?.map((e) => ({
        id: e.id,
        title: e.title || '',
        content: e.htmlSnippet
      })) ?? []
    )
  }, [items])

  return (
    <GrandSection sectionId={widgetId}>
      <GrandAccordion
        className="accordion-item__accordion"
        items={mapped.map(({ id, title, content }) => ({
          id,
          title,
          component: <HTMLArticle content={content} />
        }))}
        mx="auto"
        maxW="2xl"
        width="100%"
      />
    </GrandSection>
  )
}

export default WidgetAccordionItem
