import type { FC } from 'react'
import type { HeadingProps } from '@chakra-ui/react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import type { ColoredItemsWidgetItemType } from 'ecosystem'
import type { GrandAspectRatioProps } from 'ui/common/GrandAspectRatio'
import type { GrandImageProps } from 'ui'
import { GrandAspectRatio, GrandImage, GrandNextLink, GrandOverlay } from 'ui'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import { COLORED_ITEMS_WIDGET_CN, coloredItemsCn as cn } from './constants'

interface ColoredItemsWidgetItemBaseProps {
  item: ColoredItemsWidgetItemType
  aspectRatio: GrandAspectRatioProps['breakpoints']
  imageProps?: Partial<GrandImageProps>
  titleProps?: Partial<HeadingProps>
}

const ColoredItemsWidgetItemBase: FC<ColoredItemsWidgetItemBaseProps> = ({
  item,
  aspectRatio,
  imageProps,
  titleProps,
  ...props
}) => {
  const { path, isExternal, color = 'primary', imgUrl, title } = item
  const isLink = Boolean(path)
  const { defaultImgSrc } = useUIGlobalsContext()

  return (
    <Flex
      className={cn(COLORED_ITEMS_WIDGET_CN.ITEM_WRAPPER)}
      direction="column"
      flex={1}
      overflow="hidden"
      {...(isLink
        ? {
            as: GrandNextLink,
            href: path,
            'aria-label': title,
            isExternal
          }
        : {})}
      {...props}>
      <Box bg="gray.300" borderRadius="inherit" overflow="hidden" position="relative" zIndex={1}>
        <GrandAspectRatio breakpoints={aspectRatio}>
          <GrandImage
            alt={title || ''}
            fill
            objectFit="cover"
            quality={90}
            src={imgUrl || defaultImgSrc}
            {...imageProps}
          />

          <GrandOverlay className={cn(COLORED_ITEMS_WIDGET_CN.ITEM_OVERLAY)} />
        </GrandAspectRatio>
      </Box>

      <Flex
        _before={{
          content: '""',
          position: 'absolute',
          bottom: '100%',
          left: '0',
          width: '100%',
          height: '40px',
          bg: color
        }}
        alignItems="center"
        bg={color}
        flex="1"
        position="relative"
        w="full">
        <Heading
          as="h4"
          className={cn(COLORED_ITEMS_WIDGET_CN.ITEM_TITLE)}
          fontSize={{
            base: 'xs',
            md: 'xl'
          }}
          fontWeight="medium"
          p={{
            base: 2,
            md: 4
          }}
          textAlign="center"
          w="full"
          {...titleProps}>
          {item.title}
        </Heading>
      </Flex>
    </Flex>
  )
}

export default ColoredItemsWidgetItemBase
