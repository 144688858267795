import type { FC } from 'react'
import { Box } from '@chakra-ui/react'
import styles from './PinMarker.module.css'

interface PinMarkerProps {
  lat: number
  lng: number
}

const PinMarker: FC<PinMarkerProps> = () => {
  return (
    <Box position="relative">
      <Box className={styles.pin} />
      <Box className={styles.pulse} />
    </Box>
  )
}

export default PinMarker
