'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import {
  ContentContainer,
  GrandAspectRatio,
  GrandDivider,
  GrandNextLink,
  GrandSection,
  GrandText,
  ResponsiveTextBox
} from 'ui'
import type { CampaignHeroWidgetType, StorefrontWidget } from 'ecosystem'
import type { DynamicHeroImageProps } from 'ui/common/DynamicHeroImage'
import DynamicHeroImage from 'ui/common/DynamicHeroImage'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import GrandOverlay from 'ui/common/GrandOverlay'
import type { WidgetImplementation } from '../utils'

export type CampaignHeroWidgetProps = WidgetImplementation<
  StorefrontWidget<CampaignHeroWidgetType>,
  {
    customComponents?: {
      ImageHero?: FC<CampaignHeroWidgetImageProps>
      Wrapper?: FC<CampaignHeroWidgetWrapperProps>
    }
  }
>

export type CampaignHeroWidgetWrapperProps = BoxProps & {
  type: 'RATIO' | 'NORATIO'
  href: string
}

export interface CampaignHeroWidgetImageProps extends DynamicHeroImageProps {
  type: 'RATIO' | 'NORATIO'
}

const _ImageHero: FC<CampaignHeroWidgetImageProps> = ({ type, ...props }) => {
  return <DynamicHeroImage {...(type === 'RATIO' ? {} : { objectFit: 'cover' })} {...props} />
}

const _Wrapper: FC<CampaignHeroWidgetWrapperProps> = ({ type, children, href, ...props }) => {
  const { mobileItemHeight } = useUIGlobalsContext()
  if (type === 'RATIO') {
    return (
      <GrandSection
        contentContainerProps={{
          p: 0
        }}
        noWidthLimit
        px={0}
        py={0}
        sectionId="campaign-hero">
        <GrandAspectRatio
          breakpoints={{
            base: '4:3',
            sm: '33:10'
          }}
          {...{ href }}
          {...props}>
          {children}
        </GrandAspectRatio>
      </GrandSection>
    )
  }
  return (
    <Flex
      h={{ base: mobileItemHeight, md: 'auto' }}
      pb="30%"
      position="relative"
      w="full"
      {...{ href }}
      {...props}>
      {children}
    </Flex>
  )
}

const CampaignHeroWidget: FC<CampaignHeroWidgetProps> = ({
  widgetId,
  widget: { widget },
  customProps = {},
  ...props
}) => {
  const { customComponents = {} } = customProps
  const { ImageHero = _ImageHero, Wrapper = _Wrapper } = customComponents
  const type = widget.withAspectRatio ? 'RATIO' : 'NORATIO'
  const [deviceImgUrl, setDeviceImgUrl] = useState<string | null>(() =>
    widget.mobileImgUrl ? null : widget.imgUrl
  )

  useEffect(() => {
    if (widget.mobileImgUrl && isMobileOnly) {
      setDeviceImgUrl(widget.mobileImgUrl)
    } else {
      setDeviceImgUrl(widget.imgUrl)
    }
    // eslint-disable-next-line -- Only on load
  }, [])

  return (
    <Wrapper
      aria-label="campaign-hero"
      as={GrandNextLink}
      className="campaign-hero__wrapper"
      color="text.light"
      data-campaignid={widget.id}
      href={widget.path || ''}
      id={widgetId}
      {...(widget.path ? { isExternal: widget.isExternal } : {})}
      {...props}
      {...{ type }}>
      <ContentContainer
        alignItems={{
          base: 'center',
          md: 'flex-start'
        }}
        bottom={0}
        className="campaign-hero__content_container"
        flexDirection="column"
        h="100%"
        justifyContent={{
          base: 'center',
          md: 'flex-end'
        }}
        left={0}
        position="absolute"
        px={{
          base: 2,
          md: 8
        }}
        right={0}
        spacing={{
          base: 1,
          md: 4
        }}
        textAlign="center"
        zIndex={2}>
        {widget.title ? (
          <ResponsiveTextBox
            className="campaign-hero__title"
            color="text.light"
            textAlign={{
              base: 'center',
              md: 'left'
            }}>
            {widget.title}
          </ResponsiveTextBox>
        ) : null}

        {widget.sub ? (
          <>
            <GrandDivider borderWidth={1} className="campaign-hero__divider" w="20%" />

            <GrandText
              className="campaign-hero__sub"
              fontSize={{
                base: 'sm',
                md: 'xl'
              }}
              fontWeight="bold"
              textAlign={{
                base: 'center',
                md: 'left'
              }}>
              {widget.sub}
            </GrandText>
          </>
        ) : null}
      </ContentContainer>

      <GrandOverlay
        className="campaign-hero__overlay"
        {...(deviceImgUrl ? {} : { backdropFilter: 'blur(10px)' })}
      />

      {!!(deviceImgUrl ?? widget.imgUrl) && (
        <ImageHero
          alt={widget.alt}
          src={deviceImgUrl ?? widget.imgUrl}
          {...(deviceImgUrl
            ? {}
            : {
                quality: 1,
                sizes: '32vw'
              })}
          {...{ type }}
        />
      )}
    </Wrapper>
  )
}

export default CampaignHeroWidget
