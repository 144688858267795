'use client'
import type { FC } from 'react'
import { Fragment } from 'react'
import type { GridProps } from '@chakra-ui/react'
import { Grid, Text, useBreakpointValue } from '@chakra-ui/react'
import type { SquareWidgetType, StorefrontWidget } from 'ecosystem'
import { isOdd } from 'api'
import type { IGrandSection } from 'ui'
import { GrandSection } from 'ui'
import type { WidgetImplementation } from '../../utils'
import { WIDE_WIDGET_CN, wideWidgetCn as cn } from './constants'
import WidgetItem from './WidgetItem'

export type WideWidgetHandlerProps = WidgetImplementation<
  StorefrontWidget<SquareWidgetType>,
  {
    sectionProps?: Partial<IGrandSection>
    itemsWrapperProps?: GridProps
  }
> & {
  priority?: boolean
}

const WideWidgetHandler: FC<WideWidgetHandlerProps> = ({
  widget,
  widgetId,
  priority,
  customProps
}) => {
  const firstItemColspan = useBreakpointValue({
    base: 1,
    sm: 2,
    md: 1
  })

  return (
    <GrandSection
      className={cn(WIDE_WIDGET_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDE_WIDGET_CN.SECTION_CONTAINER),
        p: widget.widget.title || widget.widget.description ? 2 : 0
      }}
      noWidthLimit
      sectionId={widgetId}
      title={widget.widget.title}
      titleProps={{
        className: cn(WIDE_WIDGET_CN.SECTION_TITLE),
        decorationProps: {
          className: cn(WIDE_WIDGET_CN.SECTION_TITLE_DECORATION)
        }
      }}
      {...customProps?.sectionProps}>
      {widget.widget.description ? (
        <Text className={cn(WIDE_WIDGET_CN.SECTION_DESC)}>{widget.widget.description}</Text>
      ) : null}

      <Grid
        className={cn(WIDE_WIDGET_CN.WRAPPER)}
        gap={2}
        p={0}
        templateColumns={{
          base: 'repeat(1,1fr)',
          sm: 'repeat(2,1fr)',
          md: `repeat(${widget.widget.items.length}, 1fr)`
        }}
        width="100%"
        {...customProps?.itemsWrapperProps}>
        {widget.widget.items.map((item, index) => (
          <Fragment key={item.id}>
            <WidgetItem
              className={cn(WIDE_WIDGET_CN.ITEM_WRAPPER)}
              colSpan={index === 0 && isOdd(widget.widget.items.length) ? firstItemColspan : 1}
              display={{
                base: 'none',
                sm: 'block'
              }}
              imageProps={{
                priority,
                sizes: '500px'
                // sizes: `(min-width: 52em) ${
                //   index === 0 && isOdd(items.length)
                //     ? Math.round((100 / items.length) * (firstItemColspan || 1))
                //     : Math.round(100 / items.length)
                // }vw, (min-width: 40em) 50vw, 100vw`
              }}
              {...{ item }}
            />
            <WidgetItem
              className={cn(WIDE_WIDGET_CN.ITEM_WRAPPER)}
              display={{
                base: 'block',
                sm: 'none'
              }}
              imageProps={{
                priority,
                sizes: '100vw'
              }}
              {...{ item }}
            />
          </Fragment>
        ))}
      </Grid>
    </GrandSection>
  )
}

export default WideWidgetHandler
