import type { FC } from 'react'
import { Box, Flex, Stack, VStack } from '@chakra-ui/react'
import type { SummaryBoxesWidgetItemType } from 'ecosystem'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { truncateString } from 'shared-utils'
import { WIDGET_GROUP_CN } from '../../constants'
import { summaryBoxesCn as cn } from './constants'

interface SummaryBoxesWidgetItemComponentProps {
  type: 'left' | 'right'
  item: SummaryBoxesWidgetItemType
}

const SummaryBoxesWidgetItemComponent: FC<SummaryBoxesWidgetItemComponentProps> = ({
  type,
  item
}) => {
  const isLink = Boolean(item.path)

  return (
    <Box
      as={isLink ? GrandLink : 'div'}
      w="full"
      {...(isLink
        ? {
            href: item.path || '',
            isExternal: item.isExternal,
            'aria-label': item.title,
            _hover: {
              textDecoration: 'none'
            },
            cursor: {
              base: 'default',
              md: 'pointer'
            }
          }
        : {})}>
      <Stack
        alignItems="center"
        className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
        direction={{
          base: 'column',
          md: type === 'left' ? 'row-reverse' : 'row'
        }}
        px={{
          base: 2,
          sm: 10
        }}
        spacing={0}
        w="full">
        <Flex alignItems="center" flex={1} position="relative" w="full">
          <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />

          <Box
            bg="background.surface"
            borderRadius="50%"
            className="summary-boxes__item_decoration"
            display={{
              base: 'none',
              md: 'initial'
            }}
            h={8}
            left={type === 'left' ? 0 : 'auto'}
            position="absolute"
            right={type === 'left' ? 'auto' : 0}
            transform={type === 'left' ? 'translateX(-50%)' : 'translateX(50%)'}
            w={8}
            zIndex={2}
          />

          <GrandAspectRatio breakpoints={{ base: '4:3' }} className={cn('item-image-wrapper')}>
            <GrandImage
              alt={item.title || ''}
              className={cn('item-image')}
              fill
              objectFit="contain"
              sizes="(min-width: 52em) 50vw, 100vw"
              src={item.imgUrl ?? ''}
            />
          </GrandAspectRatio>
        </Flex>

        <VStack
          alignItems="flex-start"
          className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)}
          flex={0.85}
          px={{
            base: 0,
            sm: 10
          }}
          spacing={{
            base: 4,
            md: 8
          }}>
          {item.tag ? (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TAG)}
              color="text.mild"
              fontSize={{
                base: 'lg',
                sm: 'xl'
              }}
              fontWeight="normal"
              headingTag="h3"
              letterSpacing="7.2px"
              title={item.tag.toUpperCase()}
            />
          ) : null}

          {item.title ? (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
              fontSize={{
                base: '3xl',
                sm: '5xl'
              }}
              fontWeight="medium"
              headingTag="h4"
              title={item.title}
            />
          ) : null}

          {item.sub ? <HTMLArticle content={truncateString(item.sub, 500)} /> : null}
        </VStack>
      </Stack>
    </Box>
  )
}

export default SummaryBoxesWidgetItemComponent
