'use client'
import type { FC } from 'react'
import { GrandHeading, GrandNextLink, GrandSection } from 'ui'
import { Flex } from '@chakra-ui/react'
import type { StorefrontWidget, WidgetCTAWidgetType } from 'ecosystem'
import type { WidgetImplementation } from '../utils'

type WidgetCTAProps = WidgetImplementation<StorefrontWidget<WidgetCTAWidgetType>>

const WidgetCTA: FC<WidgetCTAProps> = ({
  widgetId,
  widget: {
    widget: { text, headingTag, btnLabel, path, isExternal }
  }
}) => {
  return (
    <GrandSection className="widget-cta__section" sectionId={widgetId}>
      {text ? (
        <GrandHeading
          className="widget-cta__heading"
          fontSize="2xl"
          textAlign="center"
          title={text}
          {...{ headingTag }}
        />
      ) : null}
      <Flex className="widget-cta__link_wrapper" justify="center">
        <GrandNextLink aria-label={btnLabel} href={path} {...{ isExternal }} isButton>
          {btnLabel}
        </GrandNextLink>
      </Flex>
    </GrandSection>
  )
}

export default WidgetCTA
