'use client'
import type { FC } from 'react'
import { Flex, HStack } from '@chakra-ui/react'
import { GrandSection, GrandText, ScrollDragContainer } from 'ui'
import type { CircleLinkWidgetType, StorefrontWidget } from 'ecosystem'
import type { WidgetImplementation } from '../../utils'
import { WIDGET_GROUP_CN } from '../../constants'
import type { ICircleWidgetItemProps } from './CircleLinkWidgetItem'
import CircleWidgetItemComponent from './CircleLinkWidgetItem'
import { circleLinkCn as cn } from './constants'

export type CircleLinkWidgetHandlerProps = WidgetImplementation<
  StorefrontWidget<CircleLinkWidgetType>,
  {
    itemProps: Omit<ICircleWidgetItemProps, 'item'>
  }
>

export const CircleLinkWidgetHandler: FC<CircleLinkWidgetHandlerProps> = ({
  widget,
  widgetId,
  customProps
}) => {
  return (
    <GrandSection
      className={cn(WIDGET_GROUP_CN.SECTION)}
      contentContainerProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_CONTAINER)
      }}
      py={0}
      sectionId={widgetId}
      title={widget.widget.title || ''}
      titleProps={{
        className: cn(WIDGET_GROUP_CN.SECTION_TITLE),
        containerProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_CONTAINER)
        },
        decorationProps: {
          className: cn(WIDGET_GROUP_CN.SECTION_TITLE_DECORATION)
        }
      }}>
      {Boolean(widget.widget.description) && (
        <GrandText className={cn(WIDGET_GROUP_CN.SECTION_DESC)}>
          {widget.widget.description}
        </GrandText>
      )}

      <Flex justifyContent="center">
        <ScrollDragContainer>
          <HStack
            alignItems="flex-start"
            className={cn(WIDGET_GROUP_CN.WRAPPER)}
            pb={4}
            width="100%">
            {widget.widget.items.map((item) => (
              <CircleWidgetItemComponent key={item.id} {...{ item, ...customProps?.itemProps }} />
            ))}
          </HStack>
        </ScrollDragContainer>
      </Flex>
    </GrandSection>
  )
}
