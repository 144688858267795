import { createCn } from 'shared-utils/utils'

export const mapWidgetCn = createCn('map-widget')

export const MAP_WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  WRAPPER: 'wrapper',
  TITLE: 'title',
  DESC: 'description',
  CONTENT: 'content'
}
