import type { EndpointWidgetType } from 'api/storefront-api/widgetEndpoint'
import type { StorefrontWidget } from 'ecosystem'
import { useGetCachedRequest } from 'api/utils/next'

const useWidgetsRequest = (endpointPath: string, id: string, type: EndpointWidgetType) => {
  return useGetCachedRequest<StorefrontWidget<any>[]>(
    `${endpointPath}?id=${id}&type=${type}`,
    'widgets'
  )
}

export default useWidgetsRequest
