import type { FlexProps } from '@chakra-ui/react'
import { Flex, GridItem } from '@chakra-ui/react'
import type { TwoColumnsWidgetItemType } from 'ecosystem'
import { GrandHeading, HTMLArticle } from 'ui'
import { TWO_COLUMNS_WIDGET_CN as WIDGET_CN, twoColumnsCn as cn } from './constants'

type TwoColumnsWidgetItemProps = TwoColumnsWidgetItemType & FlexProps

const TwoColumnsWidgetItem = ({ title, content, ...props }: TwoColumnsWidgetItemProps) => {
  return (
    <Flex
      alignItems="center"
      as={GridItem}
      className={cn(WIDGET_CN.ITEM_WRAPPER)}
      flexDirection="column"
      gap={8}
      {...props}>
      {title ? (
        <GrandHeading
          className={cn(WIDGET_CN.ITEM_TITLE)}
          containerProps={{
            className: cn(WIDGET_CN.ITEM_TITLE),
            m: 0,
            minHeight: '2.4em',
            fontSize: {
              base: 'xl',
              md: '2xl'
            },
            justifyContent: 'center'
          }}
          fontSize={{
            base: 'xl',
            md: '2xl'
          }}
          title={title}
        />
      ) : null}

      {content ? (
        <HTMLArticle className={cn(WIDGET_CN.ITEM_CONTENT)} content={content} fontSize="sm" />
      ) : null}
    </Flex>
  )
}

export default TwoColumnsWidgetItem
