import { createCn } from 'shared-utils'

export const coloredItemsCn = createCn('colored-items')

export const COLORED_ITEMS_WIDGET_CN = {
  SECTION: 'section',
  SECTION_CONTAINER: 'section_container',
  SECTION_TITLE: 'section_title',
  SECTION_TITLE_CONTAINER: 'section_title_container',
  SECTION_TITLE_DECORATION: 'section_title_decoration',
  SECTION_DESC: 'section_description',
  WRAPPER: 'wrapper',
  ITEM_WRAPPER: 'item_wrapper',
  ITEM_TITLE: 'item_title',
  ITEM_OVERLAY: 'item_overlay'
}
