import type { StackProps } from '@chakra-ui/react'
import { Flex, Heading, useBreakpointValue, VStack, Text } from '@chakra-ui/react'
import type { ArticleSizedBoxesWidgetItemType, Ratio } from 'ecosystem'
import type { IGrandHeadingProps } from 'ui'
import {
  GrandAspectRatio,
  GrandHeading,
  GrandImage,
  GrandLink,
  GrandOverlay,
  HTMLArticle
} from 'ui'
import { WIDGET_GROUP_CN } from '../../constants'
import { articleSizedBoxesCn as cn } from './constants'

type ArticleSizedBoxesWidgetBaseItemProps = StackProps &
  ArticleSizedBoxesWidgetItemType & {
    aspectRatio?: Ratio
    mobileAspectRatio?: Ratio
    titleProps?: Omit<IGrandHeadingProps, 'title'>
  }

const ArticleSizedBoxesWidgetBaseItem = ({
  title,
  subtitle,
  showSubOnMobile,
  sub = '',
  imgUrl,
  path,
  isExternal,
  label: linkLabel,
  aspectRatio = '16:9',
  mobileAspectRatio = '16:9',
  titleProps,
  ...props
}: ArticleSizedBoxesWidgetBaseItemProps) => {
  const isLink = Boolean(path)
  const textPx = { base: 0, md: 4 }
  const content = useBreakpointValue({
    base: showSubOnMobile ? sub : '',
    md: sub
  })

  return (
    <VStack
      alignItems="stretch"
      as={isLink ? GrandLink : 'div'}
      className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
      fontSize={{
        base: 'xs',
        md: 'md'
      }}
      justifyContent="space-between"
      spacing={4}
      w="full"
      {...(isLink
        ? {
            href: path || '',
            isExternal,
            'aria-label': title,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}
      {...props}>
      <VStack
        spacing={{
          base: 4,
          md: 6
        }}
        w="full">
        {subtitle || imgUrl ? (
          <VStack
            alignItems="stretch"
            className={cn('item_subtitle_img_wrapper')}
            spacing={{
              base: 2,
              md: 4
            }}
            w="full">
            {subtitle ? (
              <Heading
                as="div"
                className={cn(WIDGET_GROUP_CN.ITEM_SUBTITLE)}
                color="text.darkGrey"
                fontSize={{
                  base: 'xs',
                  md: 'xl'
                }}
                fontWeight="normal"
                px={textPx}>
                {subtitle}
              </Heading>
            ) : null}

            {imgUrl ? (
              <GrandAspectRatio
                borderRadius="md"
                breakpoints={{ base: mobileAspectRatio, sm: aspectRatio }}
                className={cn('item_img_wrapper')}
                overflow="hidden">
                <GrandImage
                  alt={title || ''}
                  fill
                  objectFit="cover"
                  sizes="(min-width: 52em) 50vw, 100vw"
                  src={imgUrl}
                />

                <GrandOverlay className={cn(WIDGET_GROUP_CN.ITEM_OVERLAY)} />
              </GrandAspectRatio>
            ) : null}
          </VStack>
        ) : null}

        <VStack px={textPx} spacing={4} w="full">
          {title ? (
            <GrandHeading
              className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
              containerProps={{
                my: 0,
                mr: 0
              }}
              fontSize={{
                base: 'sm',
                md: '2xl'
              }}
              fontWeight="medium"
              headingTag="h4"
              title={title}
              withDecoration={false}
              {...titleProps}
            />
          ) : null}

          {content ? (
            <Flex w="full">
              <HTMLArticle className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)} content={content} />
            </Flex>
          ) : null}
        </VStack>
      </VStack>

      {linkLabel ? (
        <Flex px={textPx}>
          <Text
            _hover={{
              textDecoration: 'none'
            }}
            fontWeight="semibold"
            textDecoration="underline">
            {linkLabel}
          </Text>
        </Flex>
      ) : null}
    </VStack>
  )
}

export default ArticleSizedBoxesWidgetBaseItem
